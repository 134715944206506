html {
  width: 100%;
  height: 100%;
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", "Helvetica", sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App.container {
  width: 99%;
  flex: 1 0 auto;
  padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

select.form-control, textarea.form-control, input.form-control {
  font-size: 16px;
}

.ReactTable .rt-thead [role="columnheader"] {
  outline: 0;
}

input[type=file] {
  width: 100%;
}

.footer {
  flex-shrink: 0;
  text-align: left;
  background-color: #009788;
  color: white;
  padding: 10px;
  font-size: 12px;
}

.footer .footerLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.footer .footerLinks .copyright {
  align-items: flex-start;
}

.footer .footerLinks .fundamentalSurgery {
  margin-right: 150px;
  text-align: right;
}

.footer .footerLinks .fundamentalSurgery a {
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 0px;
}

.footer .footerLinks .fundamentalSurgery a:hover {
  color: #6bbbae;
  font-size: 14px;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .footer .footerLinks .copyright {
    display: none;
  }
}