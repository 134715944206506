.NotFound {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    padding-top: 100px;
    text-align: center;
}

.NotFound h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}