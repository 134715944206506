.video-view, .video-placeholder,  #local_stream, #local_video_info {
    min-width: 12.5vw;
    height: 23vh;
    padding: 10px;
    margin-top: 10px;
} 

.video-container {
    background-color: #EBEBEB;
}

.live-tag {
    background-color: rgb(89, 52, 93);
    padding: 5px;
    text-align: center;
    color: white;
}

.yours-tag {
    background-color: #009788;
    padding: 5px;
    text-align: center;
    color: white;
}

.available-tag {
    background-color: #888888;
    padding: 5px;
    color: white;
    text-align: center;
}