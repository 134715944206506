.Sessions {
    padding: 10px 0;
    text-align: left;
}
.Sessions h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
.Sessions h4 {
    font-family: "Open Sans", sans-serif;
}
.Sessions p {
    color: #999;
}
.Sessions div {
    padding-top: 0px;
}
.Sessions div a:first-child {
    margin-right: 20px;
}
.Sessions .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}
@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}