.Home .lander h1 {
    font-family: "Open Sans", "Helvetica", sans-serif;
    font-weight: 600;
}
.Home p {
    font-family: "Open Sans", "Helvetica", sans-serif;
}
.Home .lander ul {
    font-size: 15px;
    font-family: "Helvetica", sans-serif;
}

.Home .lander div a:first-child {
    margin-right: 20px;
}
.page-header h1 {
    font-family: "Open Sans", "Helvetica", sans-serif;
    font-weight: 600;
}

h3 {
    font-family: "Open Sans", "Helvetica", sans-serif;
}

h4 {
    font-family: "Open Sans", "Helvetica", sans-serif;
}

h2 {
    font-family: "Open Sans", "Helvetica", sans-serif;
    font-weight: 600
}
