.App {
  font-family: "Open Sans", "Helvetica" sans-serif;
  color: #777;
 }
 .App .navbar-brand {
  font-weight: bold;
 }
.App .rt-th {
  font-weight: 600;
}
